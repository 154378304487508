<template>
  <div>
    <PageLoader />
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-snackbar
            v-model="showSnackBar"
            color="black"
            right
            :timeout="timeout"
          >
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <v-layout wrap justify-center>
            <v-flex xs112 xl10>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 15px;
                            color: #b8b8b8;
                          "
                        >
                          Welcome to
                        </span>
                      </v-flex>
                      <v-flex xs12 py-4 style="cursor: pointer">
                        <!-- <router-link to="/"> -->
                        <span
                          style="
                            font-family: poppinsbold;
                            font-size: 20px;
                            cursor: pointer;
                            color: #545454;
                          "
                        >
                          LEOPARD TECH LABS
                        </span>
                        <!-- </router-link> -->
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs6 lg4 px-2 text-center>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #000;
                              "
                            >
                              Log In
                            </span>
                            <v-progress-linear
                              height="2"
                              value="100"
                              color="black"
                            ></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex pt-8 xs12 text-left>
                        <v-layout wrap justify-center>
                          <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Username
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Username"
                                outlined
                                dense
                                style="font-family: poppinsmedium"
                                v-model="email"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-center pt-2>
                          <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Password
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                style="font-family: poppinsmedium"
                                placeholder="Password"
                                type="password"
                                outlined
                                v-model="password"
                                dense
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex pt-2 xs12 text-left>
                        <router-link to="/ForgotPassword">
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Forgot Password ?
                          </span>
                        </router-link>
                      </v-flex> -->
                      <v-flex xs12 py-6>
                        <v-btn
                          block
                          tile
                          color="black"
                          light
                          :ripple="false"
                          depressed
                          dark
                          @click="validateInput"
                          class="itemValue"
                        >
                          Continue
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      email: null,
      password: null,
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      var userData = {};
      userData["email"] = this.email;
      userData["password"] = this.password;
      store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "/user/login",
        data: userData,
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status) {
            store.commit("userType", response.data.role);
            store.commit("userData", response.data.data);
            store.commit("loginUser", response.data.data.token);
            this.$router.push({ path: "/appDashboard" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.login_page {
  background-color: #2aa86638;
  background-image: url("./../../assets/images/bg.jpg");
  background-attachment: fixed;
  background-size: cover;
}
</style>
